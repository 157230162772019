import {
  Box,
  Paper,
  TextField,
  Typography,
  Alert,
  Collapse,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  DialogActions,
  Checkbox,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useEffect, useMemo, useState } from "react";
import { batch, useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";

import {
  Account,
  APIError,
  Store,
  Organisation,
  Approval,
  OrganisationUpdateRequest,
} from "./backendTypes";
import { useNavigate, useParams } from "react-router-dom";
import { listAccounts } from "./reducers/accounts";
import { listStores } from "./reducers/stores";
import {
  deleteOrganisation,
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  createApproval,
  deleteApproval,
  listApprovals,
} from "./reducers/approvals";
import StoreSelectionDialog from "SelectStoreDialog";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const headerStyle = {
  margin: "0.5em",
  paddingLeft: "8px",
};

const defaultStyle = {
  paddingLeft: "16px",
  margin: "0.5em",
};

function findOrganisation(
  id: string | undefined,
  organisations: Array<Organisation> | undefined
) {
  if (organisations === undefined) return undefined;
  const a = organisations.filter((org) => org.id === id);
  return a.length == 0 ? undefined : a[0];
}

function OrganisationComponent() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const { id } = useParams();
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );
  const selectedOrganisation = useSelector((state: RootState) =>
    findOrganisation(id, state.organisations.organisations)
  );
  const handleUpdate = () => {
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
  };
  useEffect(() => {
    handleUpdate();
  }, []);

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const deleteOrganisationApproval = useMemo(
    () => manageOrgasApproval && account?.rol === 255,
    [manageOrgasApproval, account?.rol]
  );

  useEffect(() => {
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (approvals.length === 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedStores, setSelectedStores] = useState<Store[] | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>(
    null
  );
  const [organisationName, setOrganisationName] = useState(
    selectedOrganisation?.name ?? ""
  );
  const [deleteOrganisationDlgOpen, setDeleteOrganisationDlgOpen] =
    useState(false);
  const [addStoreDlgOpen, setAddStoreDlgOpen] = useState(false);
  const [addAccountDlgOpen, setAddAccountDlgOpen] = useState(false);
  const [accountInputValue, setAccountInputValue] = useState("");
  const [clientNumber, setClientNumber] = useState("");

  const relevantStores = useMemo(() => {
    if (selectedOrganisation && stores) {
      const storeIds = selectedOrganisation.customer_sites?.map((el) => el.id);
      return stores?.filter((store) => storeIds.includes(store.csid)) ?? [];
    } else return [];
  }, [selectedOrganisation]);
  const generalModeEnabled = useMemo(
    () => Boolean(selectedOrganisation?.feature_flags["general_mode_enabled"]),
    [selectedOrganisation]
  );

  const relevantAccounts = useMemo(() => {
    if (selectedOrganisation && accounts) {
      const accountIds = selectedOrganisation.accounts?.map((el) => el.id);
      return (
        accounts?.filter((account) => accountIds.includes(account?.acid)) ?? []
      );
    } else return [];
  }, [selectedOrganisation]);

  useEffect(() => {
    if (selectedOrganisation) {
      const storeIds = selectedOrganisation.customer_sites?.map((el) => el.id);
      const orgaStores =
        stores?.filter((store) => storeIds.includes(store.csid)) ?? [];
      setSelectedStores(orgaStores);
      const accountIds = selectedOrganisation.accounts?.map((el) => el.id);
      const orgaAccs =
        accounts?.filter((account) => accountIds.includes(account?.acid)) ?? [];
      setSelectedAccounts(orgaAccs);
      setClientNumber(selectedOrganisation.client_number ?? "");
    }
  }, [selectedOrganisation]);

  const handleAddStore = () => {
    setAddStoreDlgOpen(true);
  };

  const handleAddStoreClose = () => {
    setAddStoreDlgOpen(false);
  };
  const handleDeleteOrganisation = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setDeleteOrganisationDlgOpen(true);
  };

  const handleDeleteOrganisationClose = () => {
    setDeleteOrganisationDlgOpen(false);
  };

  const handleDeleteOrganisationAction = () => {
    if (!selectedOrganisation) return;

    dispatch(
      deleteOrganisation({
        id: selectedOrganisation.id,
        session,
        backendUrl: chiefBackendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/organisations`);
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to delete organisation '${selectedOrganisation.name}': ${err.error}`
        );
      });
  };

  const handleAddAccount = () => {
    setAddAccountDlgOpen(true);
  };

  const handleAddAccountClose = () => {
    setAddAccountDlgOpen(false);
  };

  const handleAddAccountsToStores = () => {
    batch(() => {
      selectedStores?.forEach((store) => {
        selectedAccounts?.forEach((account) => {
          const approval = approvals.find(
            (appr) =>
              appr.scope === "kytron::read_events" &&
              appr.acid == account?.acid &&
              appr.rsid === store.csid
          );
          if (!approval) {
            dispatch(
              createApproval({
                acid: account?.acid,
                scope: "kytron::read_events",
                rsid: store.csid,
                rstype: 0,
                session,
                backendUrl,
              })
            );
          }
        });
      });
    });
  };
  const handleRemoveAccountsFromStores = () => {
    batch(() => {
      const removedStores = stores.filter((store) => {
        const isInSelectedStores = selectedStores?.find(
          (el) => el.csid === store.csid
        );
        const isInOrganisationStores =
          selectedOrganisation?.customer_sites.find(
            (el) => el.id === store.csid
          );
        return isInOrganisationStores && !isInSelectedStores;
      });

      // for every removed store we also delete the read approvals for the connected accounts of the organisation
      removedStores?.forEach((store) => {
        const accApprovals = selectedOrganisation?.accounts
          ?.map((account) => {
            return approvals.find(
              (appr) =>
                appr.scope === "kytron::read_events" &&
                appr.acid == account?.id &&
                appr.rsid === store.csid
            );
          })
          .filter((el) => Boolean(el)) as Approval[];
        if (accApprovals && accApprovals.length > 0) {
          dispatch(
            deleteApproval({
              asis: accApprovals.map((el) => el.asi),
              session,
              backendUrl,
            })
          );
        }
      });
      const removedAccounts = accounts.filter((account) => {
        const isInSelectedAccounts = selectedAccounts?.find(
          (el) => el.acid === account?.acid
        );
        const isInOrganisationAccounts = selectedOrganisation?.accounts.find(
          (el) => el.id === account?.acid
        );
        return isInOrganisationAccounts && !isInSelectedAccounts;
      });
      // for every removed account we also delete the read approvals for the connected stores of the organisation
      removedAccounts?.forEach((account) => {
        const storeApprovals = selectedOrganisation?.customer_sites
          ?.map((store) => {
            return approvals.find(
              (appr) =>
                appr.scope === "kytron::read_events" &&
                appr.acid == account?.acid &&
                appr.rsid === store.id
            );
          })
          .filter((el) => Boolean(el)) as Approval[];
        if (storeApprovals && storeApprovals.length > 0) {
          dispatch(
            deleteApproval({
              asis: storeApprovals.map((el) => el.asi),
              session,
              backendUrl,
            })
          );
        }
      });
    });
  };

  const handleUpdateGeneralModeFlag = (value: boolean) => {
    if (selectedOrganisation) {
      const payload: OrganisationUpdateRequest = {
        id: selectedOrganisation.id,
        name: selectedOrganisation?.name,
        customer_sites: selectedOrganisation.customer_sites,
        client_number: selectedOrganisation.client_number,
        accounts: selectedOrganisation.accounts,
        feature_flags: {
          ...selectedOrganisation.feature_flags,
          general_mode_enabled: value,
        },
        session,
        backendUrl: chiefBackendUrl,
      };

      dispatch(updateOrganisation(payload))
        .unwrap()
        .then(() => {
          handleUpdate();
          setSuccessMessage(
            `General mode for organisation '${selectedOrganisation?.name}' successfully updated`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to update organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleUpdateOrganisation = () => {
    if (selectedOrganisation) {
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: organisationName ?? selectedOrganisation?.name,
          client_number:
            clientNumber && clientNumber !== selectedOrganisation?.client_number
              ? clientNumber
              : selectedOrganisation?.client_number,
          customer_sites: selectedStores?.map((el) => ({ id: el.csid })) ?? [],
          accounts: selectedAccounts?.map((el) => ({ id: el.acid })) ?? [],
          feature_flags: { ...selectedOrganisation.feature_flags },
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          handleRemoveAccountsFromStores();
          handleAddAccountsToStores();
          setAddStoreDlgOpen(false);
          setAddAccountDlgOpen(false);
          handleUpdate();
          setSuccessMessage(
            `Organisation '${selectedOrganisation?.name}' successfully updated`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to update organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              {selectedOrganisation?.name}
            </Typography>
            <div
              className="boxb"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              <TextField
                id="outlined-disabled"
                label="Organisation Name"
                value={organisationName}
                onChange={(e) => setOrganisationName(e.target.value)}
                sx={{ width: "400px", marginRight: 1 }}
              />
              {organisationName &&
              organisationName !== selectedOrganisation?.name ? (
                <Button onClick={handleUpdateOrganisation} variant="contained">
                  Save
                </Button>
              ) : null}
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-disabled"
                label="Organisation-ID"
                value={selectedOrganisation?.id}
                sx={{ width: 400 }}
              />
            </div>
            <div
              className="boxb"
              style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            >
              <TextField
                id="outlined-disabled"
                label="Client Number"
                value={clientNumber}
                onChange={(e) => setClientNumber(e.target.value)}
                sx={{ width: "400px", marginRight: 1 }}
              />
              {clientNumber &&
              clientNumber !== selectedOrganisation?.client_number ? (
                <Button onClick={handleUpdateOrganisation} variant="contained">
                  Save
                </Button>
              ) : null}
            </div>

            <div
              className="boxb"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FormGroup
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <FormLabel id="active-label">Generic Mode enabled:</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={generalModeEnabled}
                      onChange={(_e, checked) =>
                        handleUpdateGeneralModeFlag(checked)
                      }
                      name="active"
                    />
                  }
                  label="Active"
                  sx={{ width: "100px", marginLeft: 2, marginRight: 2 }}
                />
              </FormGroup>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Stores
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Store-ID</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Cluster</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Store</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantStores &&
                      relevantStores.map((relevantStore) => (
                        <TableRow
                          key={relevantStore?.csid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {relevantStore?.csid}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.pg_idx + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.url}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.description}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {manageOrgasApproval ? (
                <Button
                  aria-label="add"
                  onClick={handleAddStore}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ paddingTop: "1em" }}
                >
                  Manage Stores
                </Button>
              ) : null}
              <StoreSelectionDialog
                open={addStoreDlgOpen}
                onClose={handleAddStoreClose}
                stores={stores}
                selectedStores={selectedStores}
                onStoreSelect={setSelectedStores}
                onUpdate={handleUpdateOrganisation}
              />
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Accounts
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Account-ID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantAccounts &&
                      relevantAccounts.map((relevantAccount) => (
                        <TableRow
                          key={relevantAccount?.acid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {relevantAccount?.acid}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantAccount?.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantAccount?.role}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {manageOrgasApproval ? (
                <Button
                  aria-label="add"
                  onClick={handleAddAccount}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ paddingTop: "1em" }}
                >
                  Manage Accounts
                </Button>
              ) : null}
              <Dialog open={addAccountDlgOpen} onClose={handleAddAccountClose}>
                <DialogTitle>Add accounts to organisation</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "60vh" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-accounts"
                    options={accounts}
                    sx={{ width: "100%", paddingRight: "2em" }}
                    isOptionEqualToValue={(option, selection) => {
                      return option.acid === selection.acid;
                    }}
                    getOptionLabel={(option) => `${option.email}`}
                    inputValue={accountInputValue}
                    onInputChange={(event, value, reason) => {
                      if (event && event.type === "blur") {
                        setAccountInputValue("");
                      } else if (reason !== "reset") {
                        setAccountInputValue(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Account" />
                    )}
                    style={defaultStyle}
                    disableCloseOnSelect={true}
                    multiple
                    value={selectedAccounts ?? []}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 4 }}
                          checked={selected}
                        />
                        {option.email}
                      </li>
                    )}
                    onChange={(_event: any, newValue) =>
                      setSelectedAccounts(newValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddAccountClose}>Cancel</Button>
                  <Button
                    onClick={handleUpdateOrganisation}
                    disabled={!selectedAccounts}
                  >
                    Update
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {deleteOrganisationApproval ? (
              <div style={{ paddingBottom: 48 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, paddingBottom: 1 }}
                  style={headerStyle}
                >
                  Danger Zone
                </Typography>
                <div className="boxb">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteOrganisation}
                    disabled={
                      !selectedOrganisation ||
                      selectedOrganisation?.customer_sites.length > 0
                    }
                  >
                    Delete Organisation
                  </Button>
                  {!selectedOrganisation ||
                  selectedOrganisation?.customer_sites.length > 0 ? (
                    <p>
                      <b>
                        You must delete or remove all stores for this
                        organisation before deleting
                      </b>
                    </p>
                  ) : null}
                  <Dialog
                    open={deleteOrganisationDlgOpen}
                    onClose={handleDeleteOrganisationClose}
                  >
                    <DialogTitle>
                      Delete organisation {selectedOrganisation?.name ?? ""}
                    </DialogTitle>
                    <DialogContent>
                      <p>
                        Are you sure you want to delete this organisation:{" "}
                        <b>{selectedOrganisation?.name ?? ""}</b>
                      </p>
                      <p> This action can't be reversed.</p>
                      {errorMessage ? (
                        <Alert
                          variant="outlined"
                          severity="error"
                          onClose={() => {
                            setErrorMessage("");
                          }}
                        >
                          {errorMessage}
                        </Alert>
                      ) : null}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteOrganisationClose}>
                        Cancel
                      </Button>
                      <Button
                        onClick={handleDeleteOrganisationAction}
                        color="error"
                        variant="contained"
                      >
                        Delete Organisation
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            ) : null}
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default OrganisationComponent;
